class Hero extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = `
      <div class="hero">
        <a href="index.html" class="logo">
          <h1>Jokers &amp; Queens</h1>
          <h2>Pop • Jazz • Latin • Blues</h2>
        </a>
        <nav>
          <ol>
            <li><a href="/index.html"><span class="d-none d-md-inline">Die </span>Band</a></li>
            <li><a href="/termine.html">Termine</a></li>
            <li><a href="/kontakt.html">Kontakt</a></li>
            <li class="divider"></li>
            <li class="icon"><a href="https://www.instagram.com/jokersandqueens/" target="_blank"><span class="fa fa-instagram fa-2x"></span></a></li>
          </ol>
        </nav>
      </div>
    `;
  }
}

customElements.define('hero-component', Hero);
